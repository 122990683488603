import request from 'superagent';
export var HOSTNAME = "videoserver.apprikart.com"
export var API_BASE = `https://${HOSTNAME}/`;

var backend = null;

let DEFAULT_HEADERS = {
  "Content-Type": "application/json"
}

export default class Backend {

  constructor(props) {
    this.username = null;
    this.getJSONResult = this.getJSONResult.bind(this);
    this.getJSONResultForQuery = this.getJSONResultForQuery.bind(this);
    this.postData = this.postData.bind(this);
  }

  static get() {
    if (backend === null) {
      console.log("Creating new backend instance");
      backend = new Backend();
      return backend
    }
    return backend
  }

  static getUUID4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  setCanJoinMeeting() {
    sessionStorage.setItem("canJoinMeeting", "true")
  }

  canJoinMeeting() {
    return sessionStorage.getItem("canJoinMeeting") === 'true';
  }

  getAgreeToTermsState(){
    return sessionStorage.getItem("agreedToTerms")
  }

  agreedToTerms() {
    return this.getAgreeToTermsState() === 'true'
  }

  toggleAgreeToTermsState(state){
    sessionStorage.setItem("agreedToTerms", state)
  }

  isPermissionGranted() {
    return sessionStorage.getItem("isPermissionGranted") === "true"
  }

  setPermissionGranted() {
    sessionStorage.setItem("isPermissionGranted", "true")
  }

  setPermissionDenied() {
    sessionStorage.setItem("isPermissionGranted", "false")
  }

  removePermissionInfo() {
    sessionStorage.removeItem("isPermissionGranted")
  }

  endSession() {
    console.log("ending session")
    sessionStorage.clear()
  }

  getCookie(name) {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
      let [k, v] = el.split('=');
      cookie[k.trim()] = v;
    })
    return cookie[name];
  }


  async getJSONResult(path) {
    try {
      let response = await fetch(API_BASE + path)
      let data = await response.json()
      return data
    } catch (error) {
      console.error(error)
      throw error
    }
  }


  async getJSONResultForQuery(path, query) {
    var url = new URL(API_BASE + path)
    Object.keys(query).forEach(key => url.searchParams.append(key, query[key]))

    try {
      let response = await fetch(url)
      let data = await response.json()
      if (data.error) {
        throw data.error
      }
      return data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getStreamInfo(streamID, roomID) {
    let path = `kia_vc_api/v1/get_display_name/`
    let query = {
      user_type: "customer",
      room: roomID,
      stream_id: streamID
    }
    return await this.getJSONResultForQuery(path, query)
  }

  setUsername(username) {
    localStorage.setItem("username", username)
    console.log("username set", username)
  }

  getUsername() {
    return (localStorage.getItem("username") || "")
  }

  removeUsername(){
    localStorage.removeItem("username")
  }

  async setStreamInfo(streamID, roomID) {
    let path = `kia_vc_api/v1/update_stream_id/`
    let query = {
      user_type: "customer",
      room: roomID,
      stream_id: streamID,
      display_name: this.getUsername(),
    }
    return await this.getJSONResultForQuery(path, query)
  }



  async postData(path, data = {}, headers = DEFAULT_HEADERS) {

    let url = new URL(API_BASE + path)

    let formData = new FormData()
    Object.keys(data).forEach(k => {
      formData.append(k, data[k])
    })
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // headers, // manual, *follow, error
      body: formData // body data type must match "Content-Type" header
    });
    return response.json();
  }

  async uploadFileToServer(data) {
    let path = "kia_vc_api/v1/upload_vc_file/"
    let headers = {
      "Content-Type": "multipart/form-data"
    }
    return await this.postData(path, data, headers)
  }


}
