import Backend from "./backend"
import '../resources/js/webrtc_adaptor'
import { WebRTCAdaptor } from "../resources/js/webrtc_adaptor"
import ConferencePage from '../pages/ConferencePage'
import { USER_TYPES } from "./const"
import * as Sentry from "@sentry/browser";
import { toast } from 'react-toastify';


export const MESSAGE_TYPES = {
    "TEXT_MESSAGE": "TEXT_MESSAGE",
    "FILE_MESSAGE": "FILE_MESSAGE",
}

export class Participant {
    constructor(props) {
        this.id = props.id
        this.image = props.image
        this.name = props.name || ""
        this.is_muted = props.is_muted
        this.is_invisible = props.is_invisible
        this.is_sharing_screen = props.is_sharing_screen || false
        this.stream_id = props.stream_id
        this.is_current_user = props.is_current_user
        this.user_type = props.user_type || USER_TYPES.CONSUMER
    }
}

export class Message {
    constructor(props) {
        this.message = props.message
        this.author = props.author
        this.timestamp = props.timestamp || (new Date()).getTime()
        this.streamID = props.streamID
        this.is_seen = props.is_seen || false
        this.message_type = props.message_type
        this.fileSize = props.fileSize
        this.fileName = props.fileName
        this.serverFilePath = props.serverFilePath
    }
}

const ORIENTATION_PORTRAIT = "ORIENTATION_PORTRAIT"
const ORIENTATION_LANDSCAPE = "ORIENTATION_LANDSCAPE"

let csh = null;

export class ConferenceStreamsHandler {
    constructor(props) {
        this.webRTCAdaptor = null
        this.roomID = props.roomID
        this.streamID = null
        this.streams = []
        this.streamInfos = {}
        this.backend = Backend.get()
        this.roomInfoInterval = null
        this.autoRepublishIntervalJob = null
        this.is_muted = props.is_muted
        this.is_invisible = props.is_invisible
        this.peerStreams = {}
        this.localStream = null
        this.is_sharing_screen = false
        this.is_leaving = false
        this.is_joining = false
        this.reInitWebRTC = this.reInitWebRTC.bind(this)
    }

    static get(roomID, is_muted, is_invisible) {
        if (csh === null) {
            console.log("Creating new ConferenceStreamsHandler instance", is_muted, is_invisible);
            csh = new ConferenceStreamsHandler({ roomID, is_muted, is_invisible });
            return csh
        }
        return csh
    }

    joinRoom() {
        if(!this.is_joining){
            this.is_joining = true
            console.log("joining room", this.roomID, this.webRTCAdaptor)
            this.webRTCAdaptor?.joinRoom(this.roomID);
        }
    }

    publish() {
        this.webRTCAdaptor?.publish(this.streamID);
    }

    removeStream(streamID) {
        console.log("removing stream", this.streamID)
        this.streams = this.streams.filter(s => s !== streamID)
        Object.keys(this.streamInfos)
            .filter(stream => !this.streams.includes(stream))
            .forEach(stream => delete this.streamInfos[stream]);
        this.setStreams(this.streams)
    }

    sendNotificationEvent(eventType, eventData = {}) {
        if (this.isDataChannelOpen) {
            try{
                let notEvent = { streamId: this.streamID, eventType: eventType, ...eventData };
                this.webRTCAdaptor.sendData(this.streamID, JSON.stringify(notEvent));
                console.log("[WEBRTC] Sent notification event");
            }catch{
                console.log("[WEBRTC] Could not send the notification because data channel is not open.");
            }

        } else {
            console.log("[WEBRTC] Could not send the notification because data channel is not open.");
        }
    }

    onScreenShareStateChange(is_sharing_screen) {
        throw Error("No one subscribed to onScreenShareStateChange")
    }

    onMessageReceived(message){
        throw Error("No one subscribed to onMessageReceived")
    }

    startScreenShare() {
        // this.webRTCAdaptor.callbackError("ScreenSharePermissionDenied")
        // this.is_sharing_screen = true
        this.webRTCAdaptor.switchDesktopCapture(this.streamID);
        // this.onScreenShareStateChange(this.is_sharing_screen)
    }

    stopScreenShare() {
        // this.is_sharing_screen = false
        this.webRTCAdaptor.switchVideoCameraCapture(this.streamID, "camera");
        // this.onScreenShareStateChange(this.is_sharing_screen)
    }

    async getStreamInfo(streamID) {
        await this.updateStreamInfos()
        let that_stream_info = Object.values(this.streamInfos).filter(s => s.stream_id === streamID)
        if (that_stream_info.length >= 1) {
            that_stream_info = that_stream_info[0]
            return that_stream_info
        } else {
            console.error("stream info missing", streamID, that_stream_info, this.streamInfos)
        }
    }

    async handleNotificationEvent(obj) {
        console.log("Received data : ", obj.event.data);
        var notificationEvent = JSON.parse(obj.event.data);
        if (notificationEvent != null && typeof (notificationEvent) == "object") {
            var eventStreamId = notificationEvent.streamId;
            var eventType = notificationEvent.eventType;
            if (!this.streams.includes(eventStreamId)) {
                this.streams.push(eventStreamId)
            }
            let that_stream_info = await this.getStreamInfo(eventStreamId)
            if (!that_stream_info) {
                return
            }
            if (eventType === "CAM_TURNED_OFF") {
                console.log("[WEBRTC] Camera turned off for : ", eventStreamId);
                that_stream_info.is_invisible = true
            } else if (eventType === "CAM_TURNED_ON") {
                console.log("[WEBRTC] Camera turned on for : ", eventStreamId);
                that_stream_info.is_invisible = false
            } else if (eventType === "MIC_MUTED") {
                console.log("[WEBRTC] Microphone muted for : ", eventStreamId);
                that_stream_info.is_muted = true
            } else if (eventType === "MIC_UNMUTED") {
                console.log("[WEBRTC] Microphone unmuted for : ", eventStreamId);
                that_stream_info.is_muted = false
            } else if (eventType === "SCREEN_SHARE_TURNED_ON") {
                console.log("[WEBRTC] Screen sharing started for : ", eventStreamId);
                that_stream_info.is_sharing_screen = true
            } else if (eventType === "SCREEN_SHARE_TURNED_OFF") {
                console.log("[WEBRTC] Screen sharing stopped for : ", eventStreamId);
                that_stream_info.is_sharing_screen = false
            } else if (eventType === ORIENTATION_LANDSCAPE || eventType === ORIENTATION_PORTRAIT) {
                this.onPeerOrientationChange(eventType, eventStreamId)
            } else if (eventType === "TEXT_MESSAGE" || eventType === "FILE_MESSAGE") {
                console.log("[WEBRTC] Received a text/file message : ", eventStreamId);
                let message = this.extractMessageFromNotification(notificationEvent)
                if (eventStreamId !== this.streamID) {
                    this.onMessageReceived(message)
                }
                this.addMessageToMessageList(message)
            } else if (eventType === "REMOTE_USER_MIC_MUTE_BY_HOST" && notificationEvent["REMOTE_STREAM_ID"] === this.streamID){
                toast(<div className="toast-content dark">
                    You have been muted by host
                    </div>,
                    {
                        closeButton: false,
                        autoClose: 3000,
                        className: 'custom-toast-container',
                        position: toast.POSITION.BOTTOM_CENTER,
                        hideProgressBar: true,
                    })
                this.muteMic()
            }
            this.setStreams(this.streams)
        }
    }

    extractMessageFromNotification(notificationEvent) {

        let message_type
        if (notificationEvent["eventType"] === MESSAGE_TYPES.TEXT_MESSAGE) {
            message_type = MESSAGE_TYPES.TEXT_MESSAGE
        } else if (notificationEvent["eventType"] === MESSAGE_TYPES.FILE_MESSAGE) {
            message_type = MESSAGE_TYPES.FILE_MESSAGE
        } else {
            throw Error(`Unknown message type ${notificationEvent["eventType"]}`)
        }
        return new Message({
            message: notificationEvent["TEXT_MESSAGE_VALUE"],
            timestamp: notificationEvent["messageDate"],
            author: notificationEvent["display_names"],
            streamID: notificationEvent["streamId"],
            fileSize: notificationEvent["fileSize"],
            fileName: notificationEvent["fileName"],
            serverFilePath: notificationEvent["serverFilePath"],
            message_type,
        })
    }

    addMessageToMessageList(new_message) {
        throw Error("Not implemented")
    }

    onErrorSharingScreen(error_sharing_screen){
        throw Error("Error sharing screen")
    }

    sendMessageToPeers(message, message_type = MESSAGE_TYPES.TEXT_MESSAGE) {


        let eventData = {
            display_names: this.backend.getUsername(),
            messageDate: (new Date()).getTime(),
            streamId: this.streamID,
            eventType: message_type,
        }
        if (message_type === MESSAGE_TYPES.TEXT_MESSAGE) {
            eventData["TEXT_MESSAGE_VALUE"] = message
        } else if (message_type === MESSAGE_TYPES.FILE_MESSAGE) {
            eventData = { ...eventData, ...message }
        } else {
            throw Error(`Unknown message type: ${message_type}`)
        }

        console.log("sending message to peers", eventData)
        this.sendNotificationEvent(message_type, eventData)
        this.addMessageToMessageList(this.extractMessageFromNotification(eventData))
    }

    onPeerOrientationChange(new_orientation, streamID) {
        let peerVideoID = `remoteVideo${streamID}`
        let peerVideo = document.getElementById(peerVideoID)
        if (peerVideo) {
            if (new_orientation === ORIENTATION_PORTRAIT) {
                peerVideo.style.height = "100%"
                peerVideo.style.width = "auto"
            } else {
                peerVideo.style.width = "100%"
                peerVideo.style.height = "auto"
            }
        } else {
            console.log("[WEBRTC] Cannot find peer video", peerVideoID)
        }
    }


    turnOffCamera() {
        console.log("[WEBRTC] turning off camera")
        this.webRTCAdaptor.turnOffLocalCamera();
        this.is_invisible = true
        this.sendMicAndVideoStatus()
        this.setStreams(this.streams)
        this.onVideoStateChange(!this.is_invisible)
    }

    turnOnCamera() {
        console.log("[WEBRTC] turning on camera")
        this.webRTCAdaptor.turnOnLocalCamera();
        this.is_invisible = false
        this.sendMicAndVideoStatus()
        this.setStreams(this.streams)
        this.onVideoStateChange(!this.is_invisible)
    }

    muteMic() {
        console.log("[WEBRTC] turning off mic")
        this.webRTCAdaptor.muteLocalMic()
        this.is_muted = true
        this.sendMicAndVideoStatus()
        this.setStreams(this.streams)
        this.onAudioStateChange(!this.is_muted)
    }

    unMuteMic() {
        console.log("[WEBRTC] turning on mic")
        this.webRTCAdaptor.unmuteLocalMic()
        this.is_muted = false
        this.sendMicAndVideoStatus()
        this.setStreams(this.streams)
        this.onAudioStateChange(!this.is_muted)
    }

    onAudioStateChange(new_state) {
        throw Error("No one subscribed to onAudioStateChange")
    }

    onVideoStateChange(new_state) {
        throw Error("No one subscribed to onVideoStateChange")
    }

    async updateStreamInfos() {
        let addedStreamInfos = []
        for (let i = 0; i < this.streams.length; i++) {
            const stream = this.streams[i];
            if (!Object.keys(this.streamInfos).includes(stream)) {
                addedStreamInfos.push(stream)
                this.streamInfos[stream] = {
                    ...await this.backend.getStreamInfo(stream, this.roomID),
                    roomID: this.roomID,
                    streamID: stream,
                }
            }
        }
        return addedStreamInfos
    }

    onParticipantsChange() {
        throw Error("No one subscribed to participants change")
    }

    async setStreams(streams) {
        this.streams = [...new Set(streams)] || []
        const index = this.streams.indexOf(this.streamID);
        if (index > -1) {
            this.streams.splice(index, 1);
        }
        let toPlayStreams = await this.updateStreamInfos()
        let playVideoCallback = () => {
            toPlayStreams.forEach(stream => {
                console.log("playing ", this.webRTCAdaptor, stream)
                try{
                    this.webRTCAdaptor.play(stream);
                }catch(error){
                    console.log(error)
                }
            })
        }
        console.log("Stream infos", Object.values(this.streamInfos), this.streams)
        this.onParticipantsChange([...this.getParticipantsForStream(this.streams), this.getCurrentParticipant()], playVideoCallback)
    }


    leaveRoom() {
        try {
            this.is_leaving = true
            this.webRTCAdaptor?.leaveFromRoom(this.roomID);
            // this.localStream.getTracks().forEach(track => track.stop())
        } catch (e) {
            console.error(e)
        }
    }
    onKickOutFromRoom(){

    }

    getParticipantsForStream(streams) {
        return streams.map(streamID => {
            let s = this.streamInfos[streamID]
            return new Participant({
                id: s.streamID,
                name: s.display_name,
                is_current_user: false,
                stream_id: s.streamID,
                user_type: s.user_type,
                is_muted: s.is_muted,
                is_invisible: s.is_invisible,
                is_sharing_screen: s.is_sharing_screen
            })
        })
    }

    getCurrentParticipant() {
        return new Participant({
            id: this.streamID,
            name: "You",
            is_current_user: true,
            stream_id: this.streamID,
            is_muted: this.is_muted,
            is_invisible: this.is_invisible,
            is_sharing_screen: this.is_sharing_screen
        })
    }

    playVideo(obj) {
        this.peerStreams[obj.streamId] = obj.stream
    }

    sendMicAndVideoStatus() {
        if (this.is_invisible) {
            this.sendNotificationEvent("CAM_TURNED_OFF")
        } else {
            this.sendNotificationEvent("CAM_TURNED_ON");
        }
        if (this.is_muted) {
            this.sendNotificationEvent("MIC_MUTED");
        } else {
            this.sendNotificationEvent("MIC_UNMUTED");
        }
    }


    onJoiningStateChanged(joined_room) {
        throw Error("No one subscribed for joining state change")
    }

    onConnectionClosed() {
        throw Error("No one subscribed for on connection closed")
    }

    checkAndRepublishIfRequired() {
        var iceState = this.webRTCAdaptor.signallingState(this.streamID);
        if (iceState !== "stable"){
            console.log("Ice state checked = " + iceState);
        }
        if (iceState == null || iceState == "failed" || iceState == "disconnected") {
            console.log("Publish has stopped and will try to re-publish");
            try{
                clearInterval(this.roomInfoInterval)
                clearInterval(this.autoRepublishIntervalJob)
                this.webRTCAdaptor.stop(this.streamID);
                this.webRTCAdaptor.closePeerConnection(this.streamID);
                this.webRTCAdaptor.closeWebSocket();
            }catch(e){
                console.log(e)
            }
            this.initWebRTC();
        }
    }

    reInitIfNotLeaving(){
        console.log("reInitIfNotLeaving", !this.is_leaving, this.streamID)
        if (!this.is_leaving) {
            this.streams = []
            this.streamInfos = {}
            this.leaveRoom()
            clearInterval(this.autoRepublishIntervalJob)
            clearInterval(this.roomInfoInterval)
            this.webRTCAdaptor.closeStream()
            this.onConnectionClosed = () => {
                this.is_leaving = false
                this.onJoiningStateChanged(false)
                this.initWebRTC()
            }
        }
    }

    reInitWebRTC(){
        console.log("re-init-ing WebRTC")
        if(this.webRTCAdaptor){
            this.reInitIfNotLeaving()
        }else{
            console.log("this.webRTCAdaptor is null, initializing from scratch")
            this.initWebRTC()
        }
    }

    initWebRTC() {
        let websocketURL = `wss://kiadigi-connect.in:5443/WebRTCAppEE/websocket`;
        if (window.location.protocol.startsWith("http:")) {
            websocketURL = websocketURL.replace("ws://", "wss://");
        }
        let mediaConstraints = {
            video: true,
            audio: true
        };

        // let pc_config = {
        //     'iceServers': [{
        //         'urls': 'turn:onvideo.apprikart.com:3478',
        //         'username': "apprikart",
        //         'credential': "apprikart123"
        //     }]
        //     // 'iceServers': [{
        //     //     'urls': 'stun:stun1.l.google.com:19302'
        //     // }]
        // };

        let pc_config = {
            'iceTransports': 'relay',
            'iceServers': [
                {
                    url: "stun:onvideo.apprikart.com"
                },
                {
                    urls: [
                        "turn:onvideo.apprikart.com:3478?transport=udp",
                        "turn:onvideo.apprikart.com:3478?transport=tcp",
                        "turn:onvideo.apprikart.com:3478"
                    ],
                    credential: "apprikart123",
                    username: "apprikart"
                }
            ]
        };

        let sdpConstraints = {
            OfferToReceiveAudio: false,
            OfferToReceiveVideo: false,
            iceRestart: true,
        };
        console.log("initializing webrtc adaptor")
        this.webRTCAdaptor = new WebRTCAdaptor(
            {
                websocket_url: websocketURL,
                mediaConstraints: mediaConstraints,
                peerconnection_config: pc_config,
                sdp_constraints: sdpConstraints,
                isPlayMode: false,
                // debug: true,
                localVideoId: "localVideo",
                callback: async (info, obj) => {
                    if (info === "initialized") {
                        console.log("[WEBRTC]", info, obj)
                        //called by JavaScript SDK when WebSocket is connected. 
                        // if(!this.is_leaving){
                            this.joinRoom()
                        // }
                    }
                    else if (info === "joinedTheRoom") {
                        console.log("[WEBRTC]", info, obj)
                        this.streamID = obj.streamId
                        sessionStorage.setItem("streamID", this.streamID)
                        this.setStreams(obj.streams)
                        this.publish()
                        this.roomInfoInterval = setInterval(() => {
                            try {
                                this.webRTCAdaptor?.getRoomInfo(obj.room, obj.streamId);
                            } catch (error) {
                                console.log(error)
                            }
                        }, 5000);
                        try{
                            await this.backend.setStreamInfo(this.streamID, this.roomID)
                        }catch(e){
                            console.log(e)
                            if (e === "This VC is ended"){
                                this.onKickOutFromRoom()
                            }
                        }
                        this.is_joining = false
                        this.onJoiningStateChanged(true)

                        //called when this client is joined the room
                        //obj contains streamId field which this client can use to publish to the room.
                        //obj contains also the active streams in the room so that you can play them directly.
                        //In order to get the streams in the room periodically call `getRoominfo`
                    }
                    else if (info === "newStreamAvailable") {
                        console.log("[WEBRTC]", info, obj)
                        if (obj.streamId !== this.streamID) {
                            this.playVideo(obj);
                            console.log("new stream available with id: " + obj.streamId + "on the room:" + this.roomID);
                            this.sendMicAndVideoStatus()
                        }
                        //called when client is ready to play WebRTC stream.
                    }
                    else if (info === "publish_started") {
                        console.log("[WEBRTC]", info, obj, this.is_invisible, this.is_muted)
                        this.localStream = this.webRTCAdaptor.localStream
                        if (this.is_muted) {
                            console.log("[WEBRTC] turning off mic - started without audio")
                            this.muteMic()
                        }
                        if (this.is_invisible) {
                            console.log("[WEBRTC] turning off camera - started without video")
                            this.turnOffCamera()
                        }
                        this.autoRepublishIntervalJob = setInterval(() => {
                            this.checkAndRepublishIfRequired();
                        }, 3000);
                        this.setStreams(this.streams)
                        //called when stream publishing is started for this client
                    }
                    else if (info === "publish_finished") {
                        console.log("[WEBRTC]", info, obj)
                        //called when stream publishing has finished for this client
                    }
                    else if (info === "leavedFromRoom") {
                            console.log("[WEBRTC]", info, obj)
                            var room = obj.ATTR_ROOM_NAME;
                            console.log("[WEBRTC] leaved from the room:" + room);
                            if (this.roomInfoInterval) {
                                clearInterval(this.roomInfoInterval);
                            }
                            if (this.autoRepublishIntervalJob) {
                                clearInterval(this.autoRepublishIntervalJob);
                            }
                            ([...this.streams, this.streamID]).forEach(streamID => {
                                try {
                                    this.webRTCAdaptor.stop(streamID);
                                } catch (e) {
                                    console.log(e)
                                }
                            })
                            this.webRTCAdaptor.closeStream()
                            // this.localStream?.getTracks().forEach(track => track.stop())
                            this.webRTCAdaptor.closeWebSocket()
                            this.streams = []
                            this.streamInfos = {}
                            this.setStreams(this.streams)
                    }
                    else if (info === "closed") {
                        console.log("[WEBRTC]", info, obj)
                        if(this.is_leaving){
                            try {
                                this.onConnectionClosed()
                            } catch (e) {
                                console.log(e)
                            }
                            // console.log("resetting csh and webrtcadaptor")
                            // csh = null
                            // this.webRTCAdaptor = null
                        }
                         else {
                            this.checkAndRepublishIfRequired()
                        }
                        //called when websocket connection is closed
                    }
                    else if (info === "play_finished") {
                        console.log("[WEBRTC]", info, obj)
                        // this.removeStream(obj.streamId);

                        //called when a stream has finished playing
                    }
                    else if (info === "streamInformation") {
                        console.log("[WEBRTC]", info, obj)
                        //called when a stream information is received from the server.
                        //This is the response of `getStreamInfo` method		
                    }
                    else if (info === "roomInformation") {
                        // console.log("[WEBRTC]", info, obj)
                        this.setStreams(obj.streams)
                        //Called by response of `getRoomInfo` when a room information is received from the server.
                        //It contains the array of the streams in the room.
                    }
                    else if (info === "data_channel_opened") {
                        console.log("[WEBRTC]", info, obj)
                        if (obj === this.streamID) {
                            this.isDataChannelOpen = true
                        }
                        this.sendMicAndVideoStatus()
                        //called when data channel is opened
                    }
                    else if (info === "data_channel_closed") {
                        console.log("[WEBRTC]", info, obj)
                        if (obj === this.streamID) {
                            this.isDataChannelOpen = false
                            this.reInitIfNotLeaving()
                        }
                        // called when data channel is closed
                    }
                    else if (info === "data_received") {
                        console.log("[WEBRTC]", info, obj)
                        this.handleNotificationEvent(obj)
                        //called when data is received through data channel
                    }
                    else if(info === "screen_share_started"){
                        this.is_sharing_screen = true
                        this.onScreenShareStateChange(this.is_sharing_screen)
                    }
                    else if (info === "screen_share_stopped"){
                        this.is_sharing_screen = false
                        this.onScreenShareStateChange(this.is_sharing_screen)
                    }
                    else if (info == "refreshConnection") {
                        this.checkAndRepublishIfRequired();
                    }
                },
                callbackError: (error, message) => {
                    console.error("[WEBRTC] callbackError", error, message)
                    try {
                        throw error
                    } catch (error) {
                        Sentry.captureException(error)
                    }
                    if (typeof error !== "string"){
                        return
                    }
                    if (error === "already_publishing" || error === "publishTimeoutError"){
                        this.reInitIfNotLeaving()
                        // this.checkAndRepublishIfRequired()
                    }
                    if (error === "WebSocketNotConnected") {
                    }
                    if (error.indexOf("publishTimeoutError") !== -1 && this.roomInfoInterval != null) {
                        // this.checkAndRepublishIfRequired()
                    }
                    let errorMessage = JSON.stringify(error);
                    if (typeof message !== "undefined") {
                        errorMessage = message;
                    }
                    errorMessage = JSON.stringify(error);
                    if (error.indexOf("NotFoundError") !== -1) {
                        errorMessage = "Camera or Mic are not found or not allowed in your device.";
                    } else if (error.indexOf("NotReadableError") !== -1
                        || error.indexOf("TrackStartError") !== -1) {
                        errorMessage = "Camera or Mic is being used by some other process that does not not allow these devices to be read.";
                    } else if (error.indexOf("OverconstrainedError") !== -1
                        || error.indexOf("ConstraintNotSatisfiedError") !== -1) {
                        errorMessage = "There is no device found that fits your video and audio constraints. You may change video and audio constraints."
                    } else if (error.indexOf("NotAllowedError") !== -1
                        || error.indexOf("PermissionDeniedError") !== -1) {
                        errorMessage = "You are not allowed to access camera and mic.";
                        // alert("The operation is not supported by your device or permission is denied!")
                        // screen_share_checkbox.checked = false;
                        // camera_checkbox.checked = false;
                    } else if (error.indexOf("TypeError") !== -1) {
                        console.log(error)
                        if(this.is_sharing_screen){
                            this.onErrorSharingScreen("Screen Sharing is not supported or permission is denied!")
                            this.is_sharing_screen = false
                            // this.onScreenShareStateChange(this.is_sharing_screen)
                        }
                        errorMessage = "Video/Audio is required.";
                    } else if (error.indexOf("UnsecureContext") !== -1) {
                        errorMessage = "Fatal Error: Browser cannot access camera and mic because of unsecure context. Please install SSL and access via https";
                    } else if (error.indexOf("WebSocketNotSupported") !== -1) {
                        errorMessage = "Fatal Error: WebSocket not supported in this browser";
                    } else if (error.indexOf("no_stream_exist") !== -1) {
                        //TODO: removeRemoteVideo(error.streamId);
                    } else if (error.indexOf("data_channel_error") !== -1) {
                        errorMessage = "There was a error during data channel communication";
                        // this.checkAndRepublishIfRequired(message)
                    } else if (error.indexOf("ScreenSharePermissionDenied") !== -1) {
                        errorMessage = "You are not allowed to access screen share";
                        console.log(error)
                        this.onErrorSharingScreen("Screen Sharing is not supported or permission is denied!")
                        this.is_sharing_screen = false
                        // this.onScreenShareStateChange(false)
                    }
                    if (errorMessage) {
                        // alert(errorMessage);
                    }
                    //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
                }
            });
    }

}