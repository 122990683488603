import React, { Component } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ReactComponent as UserStreamPlaceholder } from '../resources/images/user_stream_placeholder_v2.svg'
import { ReactComponent as ChevronLeft } from '../resources/images/chevron_left.svg'
import { ReactComponent as ChevronRight } from '../resources/images/chevron_right.svg'
import { USER_TYPES } from '../modules/const';

class PeerStream extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
        this.videoRef = React.createRef()
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        try {
            // if (this.videoRef.current?.muted) {
            //     console.log("the video is mute trying to unmute")
            //     this.videoRef.current.muted = false
            // } else 
            if (this.props.stream && this.videoRef.current.srcObject !== this.props.stream) {
                try{
                    this.videoRef.current.srcObject = this.props.stream
                    this.videoRef.current.muted = false
                }catch(e){
                    console.log(e)
                    this.videoRef.current.src = window.URL.createObjectURL(this.props.stream);
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    render() {
        let { name, stream_id, is_invisible, is_sharing_screen } = this.props
        if (stream_id && is_invisible && !is_sharing_screen) {
            return <div key={stream_id} className="placeholder-stream-container">
                <UserStreamPlaceholder />
                <video ref={this.videoRef} className="participant-stream d-none" id={`remoteVideo${stream_id}`} autoPlay playsInline muted></video>
                <p className='participant-stream-label' >{name}</p>
            </div>
        }
        return (
            <div className="participant-stream-container">
                <p className='participant-stream-label' >{name}</p>
                <video ref={this.videoRef} className="participant-stream" id={`remoteVideo${stream_id}`} autoPlay playsInline muted></video>
            </div>
        )
    }
}


export default class VideoStreams extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedItem: 0,
            show_local_video: true
        }
        this.localVideoRef = React.createRef()
    }

    componentDidMount() {
        this.setSelectedItem(this.state.selectedItem)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.localVideoRef.current && this.localVideoRef.current.srcObject !== this.props.localStream) {
            this.localVideoRef.current.srcObject = this.props.localStream
        }
        if (this.props.participants && prevProps.participants && this.props.participants.length < prevProps.participants.length) {
            this.setSelectedItem(0)
        }
        if (this.localVideoRef?.current && this.localVideoRef?.current?.srcObject && this.localVideoRef?.current?.paused) {
            this.localVideoRef.current.play()
        }
    }

    setSelectedItem(selectedItem) {
        this.setState({
            selectedItem
        })
    }


    render() {
        let { selectedItem, show_local_video } = this.state
        let { participants, peerStreams } = this.props
        let is_waiting_for_kec = true
        participants = participants.sort((a, b) => {
            if (a?.user_type?.toLowerCase() === USER_TYPES.KEC) {
                return -1
            }
            return 1
        })
        let kec_participant = participants.filter(p => p?.user_type?.toLowerCase() === USER_TYPES.KEC)
        if (kec_participant.length > 0) {
            is_waiting_for_kec = false
        }
        let current_participant = participants.filter(p => p.is_current_user)
        if (current_participant.length >= 1) {
            current_participant = current_participant[0]
        }
        let participant_items = participants.filter(p => !p.is_current_user).map(p => {
            return <PeerStream
                key={p.id}
                is_invisible={p.is_invisible}
                is_sharing_screen={p.is_sharing_screen}
                stream={peerStreams?.[p.stream_id]} name={p.name} stream_id={p.stream_id} />
        })
        if (is_waiting_for_kec) {
            let waiting_for_kex_item = <div key={is_waiting_for_kec} className="center flex-row h-100">
                <div className="spinner-border text-dark me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div>Waiting for KEC to join</div>
            </div>
            participant_items = [waiting_for_kex_item, ...participant_items]
        }
        return (
            <div className="video-streams" >
                <Carousel
                    autoPlay={false}
                    selectedItem={selectedItem}
                    onChange={(index, item) => this.setSelectedItem(index)}
                    showStatus={false}
                    stopOnHover={true}
                    useKeyboardArrows={true}
                    interval={1000000}
                    showThumbs={false}
                >
                    {participant_items}
                </Carousel>
                {current_participant && show_local_video && <div className="current-user-stream-container">
                    {current_participant.stream_id && !current_participant.is_invisible
                        ? <div className="current-user-stream">
                            <p className="current-user-stream-label">You</p>
                            <video ref={this.localVideoRef} className="current-user-stream-video" id="localVideo" autoPlay muted playsInline></video>
                        </div>
                        :
                        <div className="placeholder-stream-container">
                            <UserStreamPlaceholder />
                            <video ref={this.localVideoRef} className="current-user-stream-video d-none" id="localVideo" autoPlay muted playsInline></video>
                            <p className='participant-stream-label' >You</p>
                        </div>
                    }

                </div>}
                <button type="button" className="btn btn-light local-stream-viewer-toggle" onClick={() => this.setState({ show_local_video: !this.state.show_local_video })}>{
                    show_local_video ? <ChevronLeft /> : <ChevronRight />}</button>
            </div>
        )
    }
}
