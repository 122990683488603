import React, { Component } from 'react'
import Backend from '../modules/backend';
import { Helmet } from 'react-helmet'
import kiaLogo from '../resources/images/kia_logo.png'
import { Link } from 'react-router-dom';
import TermsAndPolicyComponent from '../modules/TermsAndPolicyComponent';

export default class PasscodeInputPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: null,
            passcode: "",
            is_authenticating: false,
        }
        this.backend = Backend.get();
        this.goToMeetPage = this.goToMeetPage.bind(this)
        this.authenticate = this.authenticate.bind(this)
    }

    componentDidMount(){
        console.log("did mount password input page")
        if (document.openWebSockets){
            console.log("There are open websockets")
            window.location.reload()
        }
    }

    async authenticate(event) {
        event.preventDefault()
        this.setState({
            is_authenticating: true
        })
        let query = {
            user_type: "customer",
            room: this.props.match.params.meeting_id,
            auth_passcode: this.state.passcode,
        }
        try {
            let response = await this.backend.getJSONResultForQuery("kia_vc_api/v1/validate_vc/", query)
            console.log(response)
            if (response.error) {
                throw response.error
            }
            this.setState({
                is_authenticating: false
            }, () => {
                this.backend.setCanJoinMeeting()
                this.goToMeetPage()
            })
        } catch (error) {
            let errorMessage = error
            if (typeof error === "object") {
                errorMessage = error.message
            }
            this.setState({
                error: errorMessage,
                is_authenticating: false,
            })
        }
    }

    goToMeetPage() {
        console.log("going to meet page")
        this.props.history.push({
            pathname: `/meet/${this.props.match.params.meeting_id}`,
        })
    }

    render() {
        let { is_authenticating, error, passcode } = this.state
        console.log("on Password input page", this.props.match.params.meeting_id)
        return (
            <div className="password-input-page">
                <Helmet>
                    <title>Untact</title>
                </Helmet>
                <form className='password-input-form' onSubmit={this.authenticate}>
                    <img src={kiaLogo} alt="" />
                    <p className='mt-4'><strong>Enter Meeting Passcode</strong></p>
                    <div className="form-group">
                        <input autoFocus type="password" className="form-control mt-4 w-100" id="passwordInput" value={passcode} placeholder="Passcode" onChange={(event) => this.setState({ passcode: event.target.value })} />
                    </div>
                    <button disabled={is_authenticating} type="submit" className="btn btn-primary mt-4 w-100">
                        {is_authenticating ? <div>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            <span>Authenticating..</span>
                        </div> : <span>Authenticate</span>}

                    </button>
                    {error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        {error}
                        <button onClick={() => this.setState({ error: null })} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>}
                    <p className='mt-4' >Read our <TermsAndPolicyComponent /></p>
                </form>
            </div>
        )
    }
}
