import React, { Component } from 'react'
import { ReactComponent as ChangeCamIcon } from '../resources/images/change_cam.svg';
import { ReactComponent as NotificationsIcon } from '../resources/images/notifications.svg';
import { ReactComponent as MicIcon } from '../resources/images/mic.svg';
import { ReactComponent as MutedMicIcon } from '../resources/images/muted_mic.svg';
import { ReactComponent as VideoIcon } from '../resources/images/video.svg';
import { ReactComponent as StoppedVideoIcon } from '../resources/images/stopped_video.svg';
import { ReactComponent as ShareScreenIcon } from '../resources/images/share_screen.svg';
import { ReactComponent as SharingScreenIcon } from '../resources/images/sharing_screen.svg';
import { ReactComponent as ParticipantsIcon } from '../resources/images/participants.svg';
import { ReactComponent as MessagesIcon } from '../resources/images/messages.svg';
import kiaLogo from '../resources/images/kia_logo_white.png';
import VideoStreams from './VideoStreams';
import ParticipantsPage from './ParticipantsPage';
import Backend from '../modules/backend';
import MessagesPage from './MessagesPage';
import { Modal } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { ConferenceStreamsHandler, MESSAGE_TYPES } from '../modules/models';
import { toast } from 'react-toastify';
import { isMobileDevice } from '../modules/utils';


function ShareScreenPopup(props) {
    let { closeShareScreenPopup, startScreenSharing, is_sharing_screen, stopScreenSharing, error_sharing_screen } = props
    console.log("rendering screensharepopup", error_sharing_screen)
    return (
        <Modal show={true} size="sm" centered aria-labelledby="contained-modal-title-vcenter" onHide={() => closeShareScreenPopup()}>
            <Modal.Body className="share-screen-popup-container">
                {error_sharing_screen
                    ? <>
                        <div className="share-screen-popup">
                            {error_sharing_screen}
                        </div>
                        <div className="share-screen-controls">
                            <button type="button" className="btn btn-link custom" onClick={() => closeShareScreenPopup()}>OK</button>
                        </div>
                    </>
                    : is_sharing_screen
                        ? <>
                            <div className="share-screen-popup">
                                Do you you want to stop sharing your screen?
                            </div>
                            <div className="share-screen-controls">
                                <button type="button" className="btn btn-link custom" onClick={() => closeShareScreenPopup()}>Cancel</button>
                                <button type="button" className="btn btn-link custom" onClick={() => stopScreenSharing()}>Stop Sharing</button>
                            </div>
                        </>
                        : <>
                            <div className="share-screen-popup">
                                People will see everything on your screen
                            </div>
                            <div className="share-screen-controls">
                                <button type="button" className="btn btn-link custom" onClick={() => closeShareScreenPopup()}>Cancel</button>
                                <button type="button" className="btn btn-link custom" onClick={() => startScreenSharing()}>Start Sharing</button>
                            </div>
                        </>
                }
            </Modal.Body>
        </Modal>
    )
}

function EndingCallPopup(props) {
    return (
        <Modal show={true} size="sm" centered aria-labelledby="contained-modal-title-vcenter" onHide={() => { }}>
            <Modal.Body className="ending-call-popup">
                <div className="spinner-border text-dark me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div>
                    Ending call..
                </div>
            </Modal.Body>
        </Modal>
    )
}

function JoiningRoomPopup(props) {
    let { endCall } = props
    return (
        <Modal show={true} size="sm" centered aria-labelledby="contained-modal-title-vcenter" onHide={() => { }}>
            <Modal.Body >
                <div className="joining-room-popup-content">
                    <div className="spinner-border text-dark me-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <div>
                        Joining..
                    </div>
                </div>
                <div className="joining-room-controls">
                    <button type="button" className="btn btn-link custom" onClick={() => endCall()}>Cancel</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

function ConfirmationPopup(props) {
    let { text, onConfirmed, onConfirmationCancelled } = props
    return (
        <Modal show={true} size="sm" centered aria-labelledby="contained-modal-title-vcenter" onHide={() => { }}>
            <Modal.Body >
                <div className="joining-room-popup-content">
                    <div>
                        {text}
                    </div>
                </div>
                <div className="joining-room-controls">
                    <button type="button" className="btn btn-link custom" onClick={onConfirmed}>Yes</button>
                    <button type="button" className="btn btn-link custom" onClick={onConfirmationCancelled}>No</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default class ConferencePage extends Component {
    constructor(props) {
        super(props)

        console.log("audio, video", localStorage.getItem("playAudio"), localStorage.getItem("showVideo"))

        let play_audio = localStorage.getItem("playAudio") === "true"
        let show_video = localStorage.getItem("showVideo") === "true"
        console.log("conference constructor")

        this.state = {
            show_share_screen_popup: false,
            error_sharing_screen: null,
            show_video,
            play_audio,
            participants: [],
            messages: [],
            is_sharing_screen: false,
            is_ending_call: false,
            joined_room: false,
            is_confirming: false,
        }
        this.backend = Backend.get()
        this.endCall = this.endCall.bind(this)
        this.endCallAndGoToHomePage = this.endCallAndGoToHomePage.bind(this);
        this.csh = null;
        this.resetViewPortHeight = this.resetViewPortHeight.bind(this)
        this.handlePopState = this.handlePopState.bind(this);
        this.isPoppingInscreenItem = false
        this.endCallAndGoToHomePageWithConfirmation = this.endCallAndGoToHomePageWithConfirmation.bind(this)
        this.reInitStreams = this.reInitStreams.bind(this)
        this.setupConferenceEventListeners = this.setupConferenceEventListeners.bind(this)
    }


    componentDidMount() {
        // throw Error("an error")
        window.addEventListener("resize", this.resetViewPortHeight);
        window.addEventListener("popstate", this.handlePopState)
        document.addEventListener("visibilitychange", this.reInitStreams);
        // window.addEventListener("onbeforeunload", this.confirmation)
        this.resetViewPortHeight()
        console.log(this.state.play_audio, this.state.show_video)
        this.csh = ConferenceStreamsHandler.get(this.props.match.params.meeting_id, !this.state.play_audio, !this.state.show_video);
        this.csh.initWebRTC()
        this.setupConferenceEventListeners()
        setTimeout(() => {
            let conferencePage = document.querySelector('.conference-page');
            if (!conferencePage) return;
            if (window.innerHeight !== conferencePage.style.height) {
                this.resetViewPortHeight()
            }
        }, 1000)
    }

    setupConferenceEventListeners() {
        this.csh.addMessageToMessageList = (new_message) => {
            let { messages } = this.state
            messages = [new_message, ...messages]
            this.setState({
                messages
            })
        }
        this.csh.onScreenShareStateChange = (is_sharing_screen) => {
            this.setState({
                is_sharing_screen,
                show_share_screen_popup: false
            })
        }
        this.csh.onErrorSharingScreen = (error_sharing_screen) => {
            console.log("onErrorSharingScreen", error_sharing_screen)
            this.setState({
                error_sharing_screen,
                is_sharing_screen: false,
                show_share_screen_popup: true
            })
        }
        this.csh.onParticipantsChange = (participants, callback) => {
            this.setState({
                participants
            }, callback)
        }
        this.csh.onJoiningStateChanged = (joined_room) => {
            console.log("onJoiningStateChanged", joined_room)
            this.setState({
                joined_room,
            })
        }
        this.csh.onMessageReceived = (message) => {
            if (!this.shouldShowMessages()) {
                toast(<div className="toast-content">
                    <strong>{message.author}</strong> messaged you
                </div>,
                    {
                        closeButton: false,
                        autoClose: 3000,
                        className: 'custom-toast-container',
                        position: toast.POSITION.TOP_RIGHT,
                        hideProgressBar: true
                    })
            }
        }
        this.csh.onAudioStateChange = (play_audio) => {
            this.setState({
                play_audio
            })
        }
        this.csh.onVideoStateChange = (show_video) => {
            this.setState({
                show_video
            })
        }
        this.csh.onKickOutFromRoom = () => {
            this.endCall()
            this.props.history.push({
                pathname: `/join/` + this.props.match.params.meeting_id,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.hash && !this.props.location.hash) {
            this.isPoppingInscreenItem = true
        } else {
            this.isPoppingInscreenItem = false
        }
    }

    componentWillUnmount() {
        console.log("unmounting conference page")
        window.removeEventListener("resize", this.resetViewPortHeight);
        window.removeEventListener("popstate", this.handlePopState);
        document.removeEventListener("visibilitychange", this.reInitStreams)
        this.endCall()
    }

    reInitStreams(event) {
        console.log(event.type, event, isMobileDevice())
        if (!document["hidden"] && isMobileDevice()) {
            this.csh?.reInitWebRTC()
            this.setupConferenceEventListeners()
        }
    }

    handlePopState(event) {
        console.log("handling popstate", this.props.location.hash, this.isPoppingInscreenItem)
        if (!this.isPoppingInscreenItem) {
            this.endCallAndGoToHomePage()

            // this.setState({
            //     is_confirming: true
            // })
            // this.onConfirmed = ()=>{
            //     this.setState({
            //         is_confirming: false
            //     })
            //     this.endCallAndGoToHomePage()
            // }
            // this.onConfirmationCancelled = () => this.setState({is_confirming: false}) 
        }
    }

    resetViewPortHeight() {
        let conferencePage = document.querySelector('.conference-page');
        if (!conferencePage) return;
        if (window.matchMedia("(orientation: landscape)").matches) {
            // you're in LANDSCAPE mode
            conferencePage.style.height = "100vh"
            return
        }
        if (window.matchMedia("(max-width:768px)").matches || window.innerHeight !== conferencePage.style.height) {
            conferencePage.style.height = Math.max(window.innerHeight, 420) + "px";
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            conferencePage.style.overflow = "hidden"
        } else {
            conferencePage.style.height = "100vh"
        }
    }

    endCallAndGoToHomePageWithConfirmation() {
        this.setState({
            is_confirming: "Are you sure you want to end the meeting?"
        })
        this.onConfirmed = () => {
            this.setState({
                is_confirming: false
            })
            this.endCallAndGoToHomePage()
        }
        this.onConfirmationCancelled = () => {
            this.setState({
                is_confirming: false
            })
        }
    }

    endCallAndGoToHomePage() {
        this.setState({
            is_ending_call: true
        }, () => {
            this.endCall()
            let callback = () => {
                this.props.history.push({
                    pathname: `/join/` + this.props.match.params.meeting_id,
                })
            }
            this.csh.onConnectionClosed = callback
            setTimeout(callback, 5000) //10s
        })

    }

    endCall() {
        if (this.state.is_ending_call) {
            this.backend.endSession()
            this.csh?.leaveRoom()
        }
    }

    shouldShowParticipants() {
        return this.props.location.hash === "#participants"
    }

    shouldShowMessages() {
        return this.props.location.hash === "#messages"
    }

    render() {
        let { show_video, play_audio, show_share_screen_popup, participants, messages, is_sharing_screen, joined_room, is_ending_call, error_sharing_screen, is_confirming } = this.state
        let unseen_msgs_count = messages?.filter(m => (!m.is_seen && m.streamID !== this.csh.streamID)).length
        let show_participants = false, show_messages;
        if (this.shouldShowParticipants()) {
            show_participants = true
        } else if (this.shouldShowMessages()) {
            show_messages = true
        }
        console.log("conference page", show_share_screen_popup, error_sharing_screen)
        return (
            <div className="conference-page">
                <Helmet>
                    <title>Untact</title>
                </Helmet>
                <div className="controls-bar-1">
                    <div className="controls">
                        <div className="left-controls">
                            <div className="icon">
                                {/* <NotificationsIcon /> */}
                            </div>
                            <div className="icon">
                                {/* <ChangeCamIcon className="camera-change-icon" /> */}
                            </div>
                        </div>
                        <div className="conference-kia-branding">
                            <img src={kiaLogo} alt="" />
                        </div>
                        <div className='right-controls' >
                            <div className="icon">
                                <button type="button" className="btn btn-danger end-call-btn" onClick={this.endCallAndGoToHomePageWithConfirmation}>End</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="video-carousel">
                    <VideoStreams participants={participants} localStream={this.csh?.localStream} peerStreams={this.csh?.peerStreams} />
                </div>
                <div className='controls-bar-2'>
                    <div className="controls">
                        <div className="icon icon-with-label" onClick={() => {
                            if (play_audio) {
                                this.csh.muteMic()
                            } else {
                                this.csh.unMuteMic()
                            }
                        }}>
                            {
                                play_audio
                                    ? <MicIcon className='mic-icon' />
                                    : <MutedMicIcon className='mic-icon' />
                            }
                            Audio
                        </div>
                        <div className="icon icon-with-label" onClick={() => {
                            if (show_video) {
                                this.csh.turnOffCamera()
                            } else {
                                this.csh.turnOnCamera()
                            }
                        }} >
                            {
                                show_video
                                    ? <VideoIcon />
                                    : <StoppedVideoIcon />
                            }

                            Video
                        </div>
                        <div className="icon icon-with-label" onClick={() => this.setState({ show_share_screen_popup: true })}>
                            {
                                (show_share_screen_popup || is_sharing_screen)
                                    ? <SharingScreenIcon />
                                    : <ShareScreenIcon />
                            }

                            Share
                        </div>
                        <div className="icon icon-with-label" onClick={() => {
                            if (this.props.location.hash) {
                                console.log("replacing history")
                                this.props.history.replace({
                                    ...this.props.location,
                                    hash: "participants"
                                })
                            } else {
                                console.log("pushing to history")
                                this.props.history.push({
                                    ...this.props.location,
                                    hash: "participants"
                                })
                            }

                            // this.setState({
                            //     show_participants: true,
                            //     show_messages: false
                            // })
                        }}>
                            <ParticipantsIcon />
                            <div className="count">{participants?.length}</div>
                            Participants
                        </div>
                        <div className="icon icon-with-label" onClick={() => {
                            if (this.props.location.hash) {
                                this.props.history.replace({
                                    ...this.props.location,
                                    hash: "messages"
                                })
                            } else {
                                this.props.history.push({
                                    ...this.props.location,
                                    hash: "messages"
                                })
                            }
                            // this.setState({ show_messages: true, show_participants: false })
                        }}>
                            <MessagesIcon />
                            {unseen_msgs_count ? <div className="count notification">{unseen_msgs_count > 9 ? "9+" : unseen_msgs_count}</div> : null}
                            Message
                        </div>
                    </div>
                </div>
                {/* <video id="localVideo" controls autoPlay></video> */}
                {show_participants && <ParticipantsPage participants={participants} closeParticipants={() => {
                    this.props.history.replace({
                        ...this.props.location,
                        hash: ""
                    })
                    this.setState({ show_participants: false })
                }} />}
                {show_messages && <MessagesPage
                    streamID={this.csh?.streamID}
                    roomID={this.csh?.roomID}
                    currentUserInfo={participants.filter(p => p.is_current_user)?.[0]}
                    messages={messages}
                    closeMessages={() => {
                        this.props.history.replace({
                            ...this.props.location,
                            hash: ""
                        })
                        // this.setState({ show_messages: false })
                    }}
                    sendMessage={(message) => {
                        this.csh.sendMessageToPeers(message, MESSAGE_TYPES.TEXT_MESSAGE)
                    }}
                    sendFileMessage={(fileMessage) => {
                        this.csh.sendMessageToPeers(fileMessage, MESSAGE_TYPES.FILE_MESSAGE)
                    }}
                />}
                {is_ending_call && <EndingCallPopup />}
                {(!joined_room) && !is_ending_call && <JoiningRoomPopup endCall={this.endCallAndGoToHomePage} />}
                {is_confirming && <ConfirmationPopup
                    onConfirmationCancelled={this.onConfirmationCancelled}
                    onConfirmed={this.onConfirmed}
                    text={is_confirming}
                />}
                {show_share_screen_popup && <ShareScreenPopup
                    is_sharing_screen={is_sharing_screen}
                    error_sharing_screen={error_sharing_screen}
                    closeShareScreenPopup={() => this.setState({ show_share_screen_popup: false })}
                    startScreenSharing={() => {
                        this.setState({
                            show_share_screen_popup: false
                        }, () => {
                            this.csh.startScreenShare()
                        })
                    }}
                    stopScreenSharing={() => {
                        this.setState({
                            show_share_screen_popup: false
                        }, () => {
                            this.csh.stopScreenShare()
                        })
                    }}
                />}
            </div>
        )
    }
}
