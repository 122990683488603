import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ConferencePage from './pages/ConferencePage';
import PasscodeInputPage from './pages/PasscodeInputPage';
import PasscodeRequired from './pages/PasscodeRequired';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

function App() {
  return (
    <Router basename="/">
      <div className='App'>
        <Switch>
          <Route path='/join/:meeting_id' component={PasscodeInputPage} />
          <Route path="/meet/:meeting_id">
            <PasscodeRequired>
              {console.log("inside password req")}
              <Route path='/meet/:meeting_id' component={ConferencePage} />
            </PasscodeRequired>
          </Route>
          <Redirect to={"/meet/C20ANVSVUK"} />
        </Switch>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
