import React, { Component } from 'react'
import Backend from '../modules/backend'
import { Helmet } from 'react-helmet'
import { ReactComponent as GroupMeetingInfoGraphic } from '../resources/images/conference.svg'
import { ReactComponent as MutedMicIcon } from '../resources/images/audio_off.svg'
import { ReactComponent as MicIcon } from '../resources/images/audio_on.svg'
import { ReactComponent as VideoIcon } from '../resources/images/video_on.svg'
import { ReactComponent as StoppedVideoIcon } from '../resources/images/video_off.svg'
import { withRouter } from 'react-router'
import TermsAndPolicyComponent from '../modules/TermsAndPolicyComponent'
import InstructionsComponent from '../modules/InstructionsComponent'

class AccessRequiredPage extends Component {

    constructor(props) {
        super(props)

        this.backend = Backend.get()

        this.state = {
            is_joining: false,
            username: this.backend.getUsername(),
            audio_on: true,
            video_on: true,
            is_checking_permissions: true,
            form_error: null,
            agreedToTerms: this.backend.getAgreeToTermsState() === "true"
        }
        this.joinMeet = this.joinMeet.bind(this)
        this.stream = null;
        this.getAudioVideoPermissions = this.getAudioVideoPermissions.bind(this)
        this.stopTracks = this.stopTracks.bind(this)
        this.toggleAgreeToTermsState = this.toggleAgreeToTermsState.bind(this)
    }

    componentDidMount() {
        this.getAudioVideoPermissions()
    }

    async getAudioVideoPermissions() {
        console.log("getAudioVideoPermissions")
        // Older browsers might not implement mediaDevices at all, so we set an empty object first
        if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
        }

        // Some browsers partially implement mediaDevices. We can't just assign an object
        // with getUserMedia as it would overwrite existing properties.
        // Here, we will just add the getUserMedia property if it's missing.
        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = function (constraints) {

                // First get ahold of the legacy getUserMedia, if present
                var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

                // Some browsers just don't implement it - return a rejected promise with an error
                // to keep a consistent interface
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }

                // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
                return new Promise(function (resolve, reject) {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            }
        }
        try {
            console.log("checking..")
            this.stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            console.log("checked..")
            console.log("[WEBRTC] stream", this.stream?.getTracks())
            this.backend.setPermissionGranted()
            this.setState({
                is_checking_permissions: false
            })
        } catch (err) {
            console.log(err.name + ": " + err.message);
            alert("Audio/Video permission is required to place a call")
            this.backend.setPermissionDenied()
            this.setState({
                is_checking_permissions: false
            })
        }
    }

    joinMeet(event) {
        event.preventDefault()
        if (!this.backend.agreedToTerms()) {
            this.setState({
                form_error: "Please agree to terms before proceeding!"
            })
            return
        }
        this.backend.setUsername(this.state.username)
        this.stopTracks()
        localStorage.setItem("playAudio", this.state.audio_on)
        localStorage.setItem("showVideo", this.state.video_on)
        this.props.history.push({
            pathname: `/meet/${this.props.match.params.meeting_id}`,
        })
    }

    stopTracks() {
        this.stream?.getTracks().forEach((track) => track.stop());
    }

    // componentWillUnmount(){
    //     this.backend.removePermissionInfo()
    // }

    toggleAgreeToTermsState(state) {
        this.backend.toggleAgreeToTermsState(state)
        this.setState({
            agreedToTerms: state
        })
    }

    render() {
        let { is_joining, username, audio_on, video_on, is_checking_permissions, agreedToTerms, form_error } = this.state
        console.log("is permission granted", this.backend.isPermissionGranted())
        if (is_checking_permissions) {
            return <div className="h-100 flex-row center">
                <div className="spinner-border text-dark me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div>
                    Checking permissions..
                </div>
            </div>
        }
        return (
            <div className="access-required-page">
                <Helmet>
                    <title>Untact</title>
                </Helmet>
                <button className='btn btn-link custom cancel-access-btn' onClick={() => {
                    this.backend.endSession()
                    this.stopTracks()
                    this.props.history.push({
                        pathname: `/join/${this.props.match.params.meeting_id}`,
                    })
                }}>Cancel</button>
                <div className='group-meeting-infographic'>
                    <GroupMeetingInfoGraphic />
                </div>
                <h3 className='my-3'>
                    {!this.backend.isPermissionGranted()
                        ? "Permission Needed"
                        : "Permissions"
                    }
                </h3>
                <form onSubmit={this.joinMeet} className="access-required-form">
                    <div className="rounded-rect">
                        <div className="mb-2">
                            <input autoFocus type="text" className="form-control mt-4 w-100" id="name" placeholder="Your Name" value={username} onChange={(event) => this.setState({ username: event.target.value })} />
                        </div>
                        <div className='join-settings mt-3'>
                            <div className="center flex-row me-4">
                                <label className="form-check-label" htmlFor="audioCheck">
                                    {audio_on ? <MicIcon className='audio-icon' /> : <MutedMicIcon className='audio-icon' />}
                                </label>
                                <div className="d-inline-block form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="audioCheck" checked={audio_on} onChange={(event) => this.setState({ audio_on: event.target.checked })} />
                                </div>
                            </div>
                            <div className="center flex-row">
                                <label className="form-check-label" htmlFor="videoCheck">
                                    {video_on ? <VideoIcon className='video-icon' /> : <StoppedVideoIcon className='video-icon' />}
                                </label>
                                <div className="d-inline-block form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="videoCheck" checked={video_on} onChange={(event) => this.setState({ video_on: event.target.checked })} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {!this.backend.isPermissionGranted() 
                    ? <p className='my-4'>
                        We are unable to access your camera and microphone. Please check the <InstructionsComponent /> on how to enable them manually.
                    </p>
                        : <>
                            <div className='terms-and-record-form mt-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="termsCheck" checked={agreedToTerms} onChange={(event) => this.toggleAgreeToTermsState(event.target.checked)} />
                                    <label className="form-check-label" htmlFor="termsCheck">
                                        I agree to the <TermsAndPolicyComponent />
                                    </label>
                                </div>
                            </div>
                            <button type="submit" disabled={!this.backend.isPermissionGranted() || is_joining || !username} className="btn btn-primary mt-4 w-100">
                                {is_joining ? <div>
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    <span>Joining..</span>
                                </div> : <span>Join Now</span>}
                            </button>
                            {form_error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                {form_error}
                                <button onClick={() => this.setState({ form_error: null })} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}
                        </>
                }
                    
                </form>
                
            </div>
        )
    }
}


export default withRouter(AccessRequiredPage)