import React, { Component } from 'react'
import Backend from '../modules/backend';
import { ReactComponent as CloseIcon } from '../resources/images/close.svg';
import { ReactComponent as AttachmentIcon } from '../resources/images/attachment.svg';
import { MESSAGE_TYPES } from '../modules/models';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { KEYPRESS_KEYS } from '../modules/const';

function formatFileSize(bytes, si) {
    // var thresh = si ? 1000 : 1024;
    var thresh = 1024
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    // var units = si
    // ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    // : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}

export default class Messages extends Component {
    constructor(props) {
        super(props)

        this.state = {
            message: "",
            file: null,
            serverFilePath: null,
            is_uploading_file: false,
        }
        this.sendMessage = this.sendMessage.bind(this)
        this.backend = Backend.get()
        this.newMsgScrollPointRef = React.createRef()
        this.inputFileRef = React.createRef()
        this.submitForm = this.submitForm.bind(this)
        this.uploadFileToServer = this.uploadFileToServer.bind(this)
        this.sendFileMessage = this.sendFileMessage.bind(this)
        this.resetFileInput = this.resetFileInput.bind(this)
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.messages !== this.props.messages && this.newMsgScrollPointRef?.current) {
            this.newMsgScrollPointRef.current.scrollIntoView()
        }
    }

    submitForm(event) {
        console.log("submitting message form")
        if(event){
            event.preventDefault()
        }
        if (this.state.file) {
            this.sendFileMessage()
        } else {
            this.sendMessage()
        }
    }

    sendMessage() {
        this.props.sendMessage(this.state.message)
        this.setState({
            message: ""
        })
    }

    async uploadFileToServer(file) {
        let who = this.backend.getUsername()
        let vc_room = this.props.roomID
        let { user_type } = (this.props.currentUserInfo || {})
        let data = {
            who,
            user_type,
            vc_room,
            file
        }
        this.setState({
            is_uploading_file: true,
            serverFilePath: null,
        })
        try {
            let response = await this.backend.uploadFileToServer(data)
            this.setState({
                is_uploading_file: false,
                serverFilePath: response.file,
            })
        } catch (e) {
            console.log(e)
            this.setState({
                is_uploading_file: false,
            })
        }
    }

    async sendFileMessage() {
        let { file, serverFilePath } = this.state
        this.props.sendFileMessage({
            fileName: file.name,
            fileSize: file.size,
            serverFilePath: serverFilePath,
        })
        this.resetFileInput()
    }

    resetFileInput(){
        if (this.inputFileRef?.current) {
            console.log("resetting file input")
            this.inputFileRef.current.value = ""
        }
        this.setState({ file: null, is_uploading_file: false })
    }

    render() {
        let { message, file, is_uploading_file, serverFilePath } = this.state
        let { messages = [], streamID: authorStreamID } = this.props
        let onCloseBtnPressed = () => this.props.closeMessages()
        let messages_in_reverse = [...messages].reverse().map(m => {
            m.is_seen = true
            return m
        })
        return (
            <div className="messages-page">
                <div className="messages-page-heading">
                    <button type="button" className="btn btn-link custom close-messages-btn" onClick={onCloseBtnPressed}>Close</button>
                    <CloseIcon className="d-none close-messages-icon " onClick={onCloseBtnPressed} />
                    <h4>Message</h4>
                </div>
                <hr />
                <div className="messages-container-wrapper">
                    <div className='messages-container'>
                        {messages_in_reverse.map((m, index) => {
                            // console.log(m, m.streamID, authorStreamID, m.streamID === authorStreamID)
                            return <div key={index} className={m.streamID === authorStreamID ? "current-user-message-bubble" : "peer-message-bubble"}>
                                <h5 className="msg-author">{m.author}</h5>
                                {m.message_type === MESSAGE_TYPES.TEXT_MESSAGE
                                    ? <p className="msg-body">{m.message}</p>
                                    : <a style={{textDecoration: "none"}} rel="noreferrer" target="_blank" href={`https://videoserver.apprikart.com${m.serverFilePath}`} download={m.fileName}>
                                        <div className="file-message">
                                            <AttachmentIcon className="attached-file-icon" />
                                            <div className="file-meta">
                                                <div className="file-name"> {m.fileName}</div>
                                                {m.fileSize ? <div className="file-size"> {formatFileSize(m.fileSize)}</div> : null}
                                            </div>
                                        </div>
                                    </a>
                                    
                                }

                            </div>
                        })}
                        <div ref={this.newMsgScrollPointRef} />
                    </div>
                </div>
                <div className='compose-message'>
                    <form className="compose-message-form" onSubmit={this.submitForm}>
                        <div className="message-input-container">
                            {file
                                ? <div className="file-message">
                                    <CloseIcon className="delete-file-message" onClick={() => this.resetFileInput()} />
                                    {is_uploading_file
                                        ? <div className="spinner-border spinner-border-sm mx-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        : <AttachmentIcon className="attached-file-icon" />}
                                    <div className="file-meta">
                                        <div className="file-name"> {file.name}</div>
                                        <div className="file-size"> {formatFileSize(file.size)}</div>
                                    </div>
                                </div>
                                : <>
                                    {/* <input className="form-control message-input" value={message} placeholder="Type a message" type="text" onChange={(event) => this.setState({ message: event.target.value })} /> */}
                                    <TextareaAutosize
                                        rowsMax={3}
                                        aria-label="maximum height"
                                        placeholder="Type a message"
                                        className="form-control message-input"
                                        value={message}
                                        onKeyPress={(event)=>{
                                            if(event.key === KEYPRESS_KEYS.ENTER && !event.shiftKey){
                                                event.preventDefault();
                                                this.submitForm()
                                            }
                                        }}
                                        onChange={(event) => this.setState({ message: event.target.value })}
                                    />
                                    <AttachmentIcon className="attachment-icon" onClick={() => {
                                        this.inputFileRef?.current?.click()
                                    }} />
                                </>
                            }
                            <input className="d-none" type="file" ref={this.inputFileRef} onChange={(event => {
                                console.log(event.target.files)
                                if (event.target.files?.length > 0) {
                                    let file = event.target.files[0]
                                    this.setState({
                                        file,
                                    }, () => this.uploadFileToServer(file))
                                } else {
                                    this.setState({
                                        file: null,
                                    })
                                }
                            })} />

                        </div>
                        <button disabled={!message && !(file && serverFilePath)} type="submit" className="btn btn-link custom send-message-btn">Send</button>
                    </form>
                </div>
            </div>
        )
    }
}
