import React, { Component } from 'react'
import Backend from '../modules/backend';
import { withRouter, Redirect } from 'react-router';
import AccessRequiredPage from './AccessRequiredPage';

class PasscodeRequired extends Component {

    constructor(props) {
        super(props);

        this.backend = Backend.get();
    }


    hasAudioAndVideoAccess() {
        return this.backend.isPermissionGranted()
    }



    render() {
        let meeting_id = this.props.match?.params?.meeting_id
        console.log("passcode required component", this.backend.canJoinMeeting(), window.location.pathname, this.props)
        if (this.backend.canJoinMeeting()) {
            // console.log(this.backend.canJoinMeeting(), this.hasAudioAndVideoAccess(), this.backend.getUsername() )
            if (!this.hasAudioAndVideoAccess() || !this.backend.getUsername() || !this.backend.agreedToTerms()) {
                return <AccessRequiredPage />
            }
            return this.props.children
        } else {
            console.log("redirecting to join page")
            return <Redirect to={"/join/" + meeting_id} />
        }
    }
}

export default withRouter(PasscodeRequired)