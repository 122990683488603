import React, { Component } from 'react'
import profileImage from '../resources/images/profile_image.png'
import { ReactComponent as MicIcon } from '../resources/images/mic_dark.svg';
import { ReactComponent as VideoIcon } from '../resources/images/video_dark.svg';
import { ReactComponent as CloseIcon } from '../resources/images/close.svg';

export default class ParticipantsPage extends Component {

    render() {
        let { participants } = this.props
        let onCloseBtnPressed = () => this.props.closeParticipants()
        return (
            <div className="participants-page">
                <div className="participants-heading">
                    <button type="button" className="btn btn-link custom close-participants-btn" onClick={onCloseBtnPressed}>Close</button>
                    <CloseIcon className="d-none close-participants-icon " onClick={onCloseBtnPressed} />
                    <h4 className="participants-page-heading">Participants ({participants.length})</h4>
                </div>
                <hr />
                <div>
                    {participants.map(p => {
                        return <div key={p.id} className="participant-row px-4 py-2">
                            <div >
                                <img className="participant-image" src={p.image || profileImage} alt="" />
                            </div>
                            <div className="participant-name">
                                {p.is_current_user ? "You" : p.name}
                            </div>
                            <div className="participant-status">
                                <div className="participant-status-icon">
                                    <MicIcon className='mic-icon' fill="#05141F" style={{ opacity: p.is_muted ? 0.3 : 1 }} />
                                </div>
                                <div className="participant-status-icon">
                                    <VideoIcon fill="#05141F" style={{ opacity: p.is_invisible ? 0.3 : 1 }} />
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        )
    }
}
