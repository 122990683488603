import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'

export default class TermsAndPolicyComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showTermsPopup: false,
            showPolicyPopup: false
        }
    }


    render() {
        let { showTermsPopup, showPolicyPopup } = this.state
        return (
            <>
                <button type="button" className="btn btn-link inline" onClick={() => {
                    this.setState({
                        showTermsPopup: true,
                        showPolicyPopup: false,
                    })
                }}>Terms & conditions</button> and <button type="button" className="btn btn-link inline" onClick={() => {
                    this.setState({
                        showPolicyPopup: true,
                        showTermsPopup: false
                    })
                }}>Privacy Policy</button>
                {/* <a href="/terms_conditions.html"></a> and <a href="/privacy_policy.html"></a> */}
                {showTermsPopup && <Modal show={true} size="lg" centered dialogClassName='h-90' contentClassName='h-100' aria-labelledby="contained-modal-title-vcenter" onHide={() => this.setState({
                    showTermsPopup: false
                })}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <iframe title="Terms & Conditions" src="/terms_conditions.html" frameborder="0" width="100%" height="100%" />
                    </Modal.Body>
                </Modal>}
                {showPolicyPopup && <Modal show={true} size="lg" centered dialogClassName='h-90' contentClassName='h-100' aria-labelledby="contained-modal-title-vcenter" onHide={() => this.setState({
                    showPolicyPopup: false
                })}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <iframe title="Privacy Policy" src="/privacy_policy.html" frameborder="0" width="100%" height="100%" />
                    </Modal.Body>
                </Modal>}
            </>
        )
    }
}
