import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import {ReactComponent as LockIcon} from '../resources/images/lock.svg'


export default class InstructionsComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showInstructionsPopup: false
        }
    }

    render() {
        let { showInstructionsPopup } = this.state
        return (
            <>
                <button type="button" className="btn btn-link inline" onClick={() => {
                    this.setState({
                        showInstructionsPopup: true,
                    })
                }}> instructions </button>
                {showInstructionsPopup && <Modal show={true} size="lg" centered contentClassName='instruction-modal h-100' aria-labelledby="contained-modal-title-vcenter" onHide={() => this.setState({
                    showInstructionsPopup: false
                })}>
                    <Modal.Header closeButton>
                        <h2>Instructions</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Android</h4>
                        <ol>
                            <li>Go to <code>Settings</code></li>
                            <li>Go to the <code>Apps</code> section</li>
                            <li>Select the browser app and click on <code>App permissions</code> </li>
                            <li>Allow <code>Microphone</code> and <code>Camera</code> permissions manually</li>
                        </ol>
                        <h4>iPhone or iPad</h4>
                        <ol>
                            <li>Go to <code>Settings</code> app</li>
                            <li>Search <code>Chrome/Safari</code></li>
                            <li>Give permissions to access <code>Camera</code> and <code>Microphone</code></li>
                        </ol>
                        <h4>Web</h4>
                        <ol>
                            <li>Click on the <LockIcon className='padlock-icon' /> icon on the address bar</li>
                            <li>Select "Allow" for both <code>Camera</code> and <code>Microphone</code></li>
                        </ol>
                    </Modal.Body>
                </Modal>}
            </>
        )
    }
}
