export const USER_TYPES = {
    "CONSUMER": "customer",
    "KEC": "kec",
}

export const KEYCODES = {
    "ENTER": 13,
}

export const KEYPRESS_KEYS = {
    "ENTER": "Enter"
}